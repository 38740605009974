input:not([type='checkbox']),
.form-button,
textarea,
.ria-complete {
  @apply block font-medium text-left text-black h-16 outline-none bg-white appearance-none text-base border-black px-5 rounded-md w-full;
  transition: border-color 0.2s ease-in-out;
  border: 1px solid rgba(0, 0, 0, 0.1);
  appearance: none;
  -webkit-appearance: none;

  .small-input & {
    @apply h-12;
  }

  &.border-accent {
    border-color: var(--accent);
  }

  &[type='range'] {
    border: none;
    appearance: auto;
  }

  &.calculator-input {
    background-color: var(--accent) !important;
    @apply text-white text-center w-full sm:w-32 lg:w-40;
    height: auto;
  }

  &.calculator-input-disabled {
    @apply text-center w-40 lg:text-xl;
    height: auto;
  }

  &::placeholder {
    @apply text-black opacity-50 text-sm;
  }

  &:focus {
  }

  // &.bordered {
  //   @apply border-t border-l border-r border-black;
  // }

  &.wpcf7-not-valid {
    border-color: red !important;
  }

  &[type='search'] {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}

input[type='checkbox'] {
  width: 15px;
  height: 15px;
  margin-top: 0;
  border-radius: 6px;
  border-color: rgba(0, 0, 0, 0.2);
}

input[type='number'] {
  -moz-appearance: textfield;
}

textarea {
  @apply pt-6 w-full;
  resize: vertical;
  height: 120px;
}

select {
  @apply block w-full text-left text-black  rounded-md px-5 border-black h-16 bg-transparent outline-none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  appearance: none;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='13px' height='8px' viewBox='0 0 13 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 63.1 (92452) - https://sketch.com --%3E%3Ctitle%3EPath Copy%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3Crect id='path-1' x='0' y='0.5' width='1442' height='860' rx='2'%3E%3C/rect%3E%3C/defs%3E%3Cg id='Full-Fidelity' stroke='none' strokeWidth='1' fill='none' fill-rule='evenodd'%3E%3Cg id='03-Rideshur-for-Drivers' transform='translate(-1337.000000, -7385.000000)'%3E%3Cg id='CTA' transform='translate(0.000000, 7178.000000)'%3E%3Cg id='Group-21'%3E%3Cg id='Group-12'%3E%3Cg id='illo-tandem-bicycle@2x-32e9a4dbe09726624df2b39dcc5db46b9a2bb7321585542ac80f9e7a1a5c24d4'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Mask' fill='%23FAF8F6' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3Cpolyline id='Path-Copy' stroke='%23424E5C' strokeWidth='1.6' strokeLinecap='round' strokeLinejoin='round' points='1338 208.5 1343.6 214.1 1349.2 208.5'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-position: calc(100% - 20px) center;
  background-repeat: no-repeat;

  &.v2 {
    @apply px-5;
    color: #838b93;

    font-weight: 500;
    font-size: 0.85em;
    @apply font-sans;
    &.has-selected {
      color: #041b29;
      font-size: 1em;
    }
  }
}

:not(.gform_body) label:not(.no-style),
.form-label {
  //display: block;
  width: 100%;
  font-size: 16px;
  font-weight: 500;

  &:not(:last-child) {
    //margin-bottom: 25px;
  }

  input,
  select,
  textarea {
    //margin-top: 10px;
  }

  &.checkbox {
    display: flex;

    input[type='checkbox'] {
      margin-top: 0;
    }
  }
}

input::placeholder-shown,
textarea::placeholder-shown {
  color: #5c5856;
  font-size: 1rem;
  opacity: 0.5;
  @apply font-sans;
}

form.default-form-style select,
select option:first-child {
  font-size: 0.875rem;
  font-weight: 500;
  @apply font-sans;
}

form.default-form-style select:has(option:first-child[userselected='true']) {
  color: #818d94;
}

input::placeholder,
textarea::placeholder {
  color: #5c5856;
  font-size: 1rem;
  opacity: 0.5;
  @apply font-sans;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #5c5856;
  font-size: 1rem;
  opacity: 0.5;
  @apply font-sans;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #5c5856;
  font-size: 1rem;
  opacity: 0.5;
  @apply font-sans;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #5c5856;
  font-size: 1rem;
  opacity: 0.5;
  @apply font-sans;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #5c5856;
  font-size: 1rem;
  opacity: 0.5;
  @apply font-sans;
}

input[type='submit'],
button.form-button {
  @apply text-center items-center justify-center font-semibold text-black cursor-pointer h-16 text-base transition-opacity duration-200 ease-in-out border-0 bg-accent;

  &:hover {
    @apply opacity-75;
  }
}

.wpcf7-form,
.default-form-style {
  label {
    @apply mb-4 block;
    @screen md {
      @apply mb-8;
    }
  }
  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  select {
    @apply w-full mt-4;
  }

  input[type='submit'] {
    @apply w-full uppercase text-sm mt-6;
  }

  .wpcf7-response-output {
    display: none !important;
    &.default-show {
      display: block !important;
    }
  }
}

.dark-form {
  .hubspot-form {
    .hs-form {
      .hs-form-field {
        .hs-input {
          @apply text-white;
          background: #223643;
          border: 1px solid rgba(34, 54, 67, 1);
          &::placeholder {
            color: rgba(255, 255, 255, 0.5);
          }
        }
      }
      .form-columns-1,
      .hs-form-field,
      .input,
      .input input {
        width: 100%;
      }
      .hs-form-field {
        margin-bottom: 1rem;
      }
      .input input {
        margin-right: 0px;
      }
    }
  }
}
