$font-path: '/fonts';

@font-face {
  font-family: 'Open Sauce Sans';
  src:
    url('#{$font-path}/OpenSauceSans-Medium.woff2') format('woff2'),
    url('#{$font-path}/OpenSauceSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src:
    url('#{$font-path}/OpenSauceSans-Regular.woff2') format('woff2'),
    url('#{$font-path}/OpenSauceSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src:
    url('#{$font-path}/OpenSauceSans-SemiBold.woff2') format('woff2'),
    url('#{$font-path}/OpenSauceSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

.u-h1 {
  font-size: 2.375rem; // 38px
  line-height: 1.2;
  letter-spacing: -0.033em;
  @apply font-semibold;

  @screen md {
    font-size: 2.625rem;
  }
  @screen lg {
    font-size: 3.25rem; // 52px
  }
}

.u-52px {
  font-size: 2.375rem;
  line-height: 1.2;
  letter-spacing: -0.033em;
  @apply font-semibold;
  @screen md {
    font-size: 2.625rem;
  }
  @screen lg {
    font-size: 52px;
  }
}

.u-50px {
  font-size: 2.375rem;
  line-height: 1.2;
  letter-spacing: -0.033em;
  @screen lg {
    font-size: 3.125rem;
    line-height: 1.15;
  }
}

.u-h2 {
  font-size: 36px;
  line-height: 1.1;
  letter-spacing: -0.033em;
  @apply font-semibold;

  @screen md {
    font-size: 36px;
  }
  @screen xl {
    font-size: 50px;
  }
}

.text-43px {
  font-size: 32px;
  line-height: 1.1;
  letter-spacing: -0.033em;
  @apply font-semibold;

  @screen md {
    font-size: 36px;
  }
  @screen xl {
    font-size: 43px;
  }
}

.u-h3 {
  font-size: 24px;
  line-height: 1.1;
  letter-spacing: -0.033em;
  @apply font-semibold;

  @screen lg {
    font-size: 28px;
  }
}

.u-h4 {
  font-size: 32px;
  line-height: 1.1;
  letter-spacing: -0.02em;
  @apply font-bold;

  @screen md {
    font-size: 36px;
  }
}

.u-h5 {
  font-size: 20px;
  line-height: 1.1;
  letter-spacing: -0.02em;
  @apply font-bold;

  @screen md {
    font-size: 28px;
  }
}

.u-p1 {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.005em;

  @screen lg {
    font-size: 20px;
    line-height: 32px;
  }
}

.u-p2 {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.005em;

  @screen lg {
    font-size: 18px;
    line-height: 32px;
  }
}

.u-p3 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.005em;

  @screen lg {
    font-size: 16px;
    line-height: 24px;
  }
}

.u-s1 {
  font-size: 16px;
  @apply text-accent font-medium;
  @screen md {
    font-size: 18px;
  }

  section.bg-yellow & {
    @apply text-black;
  }
}

.u-e1 {
  font-size: 16px;
  @apply text-red font-medium;
  @screen md {
    font-size: 18px;
  }
}

@responsive {
  .text-48px {
    font-size: 48px;
  }

  .text-32px {
    font-size: 32px;
  }

  .text-28px {
    font-size: 28px;
  }

  .text-22px {
    font-size: 22px;
  }

  .text-21px {
    font-size: 21px;
  }
  .text-20px {
    font-size: 20px;
  }

  .text-18px {
    font-size: 18px;
  }

  .text-24px {
    font-size: 24px;
  }

  .text-17px {
    font-size: 17px;
  }

  .text-16px {
    font-size: 16px;
  }

  .text-15px {
    font-size: 15px;
  }

  .text-14px {
    font-size: 14px;
  }

  .text-13px {
    font-size: 13px;
  }

  .text-12px {
    font-size: 12px;
  }
  .text-11px {
    font-size: 11px;
  }
}

.prose li:before {
  display: none !important;
}

.prose-fix-bullets {
  ul li {
    &:before {
      display: none;
    }
  }
}

.prose-styling {
  ul {
    list-style-type: disc;
    margin-top: 1.25em;
    margin-bottom: 1.25em;
    margin-left: 1.3333em;
    li {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      padding-left: 0;
    }
  }
  p {
    margin-bottom: 1.25em;
  }
}

